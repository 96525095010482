import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    userName: null,
    userDisplayName: null,
    userEmail: null,
    initialPage:null,
    token: null,
    error: null,
    loading: false,
    url: process.env.REACT_APP_API_URL,
    changePasswordUrl: process.env.REACT_APP_CHANGE_PASS_URL,
    obj: '1',
    activityDate: "",
    addedActivity: "",
    editedActivity: "",
    addedSurvey: "",
    addedProjection: "",
    addedBhaMotor: "",
    addedBhaAssets: "",
    addedReceivedShipment: "",
    userId: "",
    userPartner: "",
    userPartnerType: "",
    userPartnerName: "",
    userPartnerLogo: "",
    userLevel: "",
    userBU: "",
    activeJob: "",
    activePhase: "",
    userSSO: "",
    userISO: "",
    passwordResetRequired: false,
    userWhiteMode: "",
    userMultiCountry: "",
    userCountry: "",
    userViewPartner: "",
    userViewStyle: "",
    sidebarLogo: "",
    addedDrillPipeAssets: "",
    userDistrict: "",
    match: [],
}

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const authSuccess = (state, action) => {

    return updateObject(state, {
        token: action.token,
        userName: action.userName,
        userDisplayName: action.userDisplayName,
        userEmail: action.userEmail,
        userId: action.userId,
        user: action.user,
        focusWell: action.focusWell,
        userLevel: action.userLevel,
        userPartner: action.userPartner,
        userPartnerType: action.userPartnerType,
        userPartnerName: action.userPartnerName,
        userPartnerLogo: action.userPartnerLogo,
        sidebarLogo: action.sidebarLogo,
        userBU: action.userBU,
        userSSO: action.userSSO,
        userISO: action.userISO,
        passwordResetRequired: action.passwordResetRequired,
        userWhiteMode: action.userWhiteMode,
        userMultiCountry: action.userMultiCountry,
        userCountry: action.userCountry,
        userViewPartner: action.userViewPartner,
        userViewStyle: action.userViewStyle,
        activeJob: action.activeJob,
        activePhase: action.activePhase,
        userDistrict: action.userDistrict,
        error: null,
        loading: false
    });
}

const authFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
    });
}

const updateActivityDate = (state, action) => {
    return updateObject(state, {
        activityDate: action.activityDate,
    });
}

const addActivity = (state, action) => {
    return updateObject(state, {
        addedActivity: action.addedActivity,
    });
}

const editActivity = (state, action) => {
    return updateObject(state, {
        editedActivity: action.editedActivity,
    });
}

const addSurvey = (state, action) => {
    return updateObject(state, {
        addedSurvey: action.addedSurvey,
    });
}

const addProjection = (state, action) => {
    return updateObject(state, {
        addedProjection: action.addedProjection,
    });
}

const addBhaMotor = (state, action) => {
    return updateObject(state, {
        addedBhaMotor: action.addedBhaMotor,
    });
}

const addBhaAssets = (state, action) => {
    return updateObject(state, {
        addedBhaAssets: action.addedBhaAssets,
    });
}

const addDrillPipeAssets = (state, action) => {
    return updateObject(state, {
        addedDrillPipeAssets: action.addedDrillPipeAssets,
    });
}

const addReceivedShipment = (state, action) => {
    return updateObject(state, {
        addedReceivedShipment: action.addedReceivedShipment,
    });
}
const setUser = (state, action) => {
    return updateObject(state, {
        user: action.user,
    });
}
const setFocusWell = (state, action) => {
    return updateObject(state, {
        focusWell: action.focusWell,
    });
}
const setUserId = (state, action) => {
    return updateObject(state, {
        userId: action.userId,
    });
}

const setUserLevel = (state, action) => {
    return updateObject(state, {
        userLevel: action.userLevel,
    });
}

const setInitialPage = (state, action) => {
    return updateObject(state, {
        initialPage: action.initialPage,
    });
}

const setUserPartner = (state, action) => {
    return updateObject(state, {
        userPartner: action.userPartner,
    });
}
const setUserBU = (state, action) => {
    return updateObject(state, {
        userBU: action.userBU,
    });
}
const setUserDistrict = (state, action) => {
    return updateObject(state, {
        userDistrict: action.userDistrict,
    });
}
const setUserMultiCountry = (state, action) => {
    return updateObject(state, {
        userMultiCountry: action.userMultiCountry,
    });
}
const setUserCountry = (state, action) => {
    return updateObject(state, {
        userCountry: action.userCountry,
    });
}
const setUserViewPartner = (state, action) => {
    return updateObject(state, {
        userViewPartner: action.userViewPartner | action.userPartner,
    });
}
const setUserViewStyle = (state, action) => {
    return updateObject(state, {
        userViewStyle: action.userViewStyle,
    });
}
const setUserPartnerType = (state, action) => {
    return updateObject(state, {
        userPartnerType: action.userPartnerType,
    });
}

const setActiveJob = (state, action) => {
    if (action && action.activeJob)
        return updateObject(state, {
            activeJob: action.activeJob,
        });
}
const setActivePhase = (state, action) => {
    return updateObject(state, {
        activePhase: action.activePhase,
    });
}

const setUrlMatch = (state, action) => {
    return updateObject(state, {
        match: action.match,
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_USER: return setUser(state, action);
        case actionTypes.SET_FOCUS_WELL: return setFocusWell(state, action);
        case actionTypes.SET_USER_ID: return setUserId(state, action);
        case actionTypes.SET_USER_LEVEL: return setUserLevel(state, action);
        case actionTypes.SET_INITIAL_PAGE: return setInitialPage(state, action);
        case actionTypes.SET_USER_PARTNER: return setUserPartner(state, action);
        case actionTypes.SET_USER_PARTNER_TYPE: return setUserPartnerType(state, action);
        case actionTypes.SET_USER_BU: return setUserBU(state, action);
        case actionTypes.SET_USER_MULTI_COUNTRY: return setUserMultiCountry(state, action);
        case actionTypes.SET_USER_COUNTRY: return setUserCountry(state, action);
        case actionTypes.SET_USER_VIEW_PARTNER: return setUserViewPartner(state, action);
        case actionTypes.SET_USER_VIEW_STYLE: return setUserViewStyle(state, action);
        case actionTypes.SET_ACTIVE_JOB: return setActiveJob(state, action);
        case actionTypes.SET_ACTIVE_PHASE: return setActivePhase(state, action);
        case actionTypes.SET_URL_MATCH: return setUrlMatch(state, action);
        case actionTypes.ACTIVITY_DATE: return updateActivityDate(state, action);
        case actionTypes.ADD_ACTIVITY: return addActivity(state, action);
        case actionTypes.EDIT_ACTIVITY: return editActivity(state, action);
        case actionTypes.ADD_SURVEY: return addSurvey(state, action);
        case actionTypes.ADD_PROJECTION: return addProjection(state, action);
        case actionTypes.ADD_BHA_MOTOR: return addBhaMotor(state, action);
        case actionTypes.ADD_BHA_ASSETS: return addBhaAssets(state, action);
        case actionTypes.ADD_DRILLPIPE_ASSETS: return addDrillPipeAssets(state, action);
        case actionTypes.ADD_RECIEVED_SHIPMENT: return addReceivedShipment(state, action);
        case actionTypes.SET_USER_DISTRICT: return setUserDistrict(state, action);
        default:
            return state;
    }
}

export default reducer;